import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/common/header";
import { Div, Text } from "atomize";
// import GetStartedBanner from "../components/homepage/GetStartedBanner"
import Footer from "../components/homepage/Footer";

const ServicePage = () => (
  <Layout>
    <div>
      <SEO
        title="Aviso de Privacidad"
        description="Mass Job application. Aplica de forma instantanea a cientos de vacantes disponibles para ti. Agrega tus datos, aplica y mira como te llegan ofertas al instante."
      />
      <Header />
      <Div p={{ x: { xs: "1.5rem", md: "8rem" }, t: "10rem", b: "10rem" }}>
        <Text textSize="heading" textWeight="500" m={{ b: "0.5rem" }}>
          CONDICIONES DE SERVICIO
        </Text>
        <Text m={{ b: "1.5rem" }} textColor="medium">
          VISIÓN GENERAL Este sitio web es operado por Quopard LATAM S.A de C.V.
          En todo el sitio, los términos "nosotros", "nos" y "nuestro" se
          refieren a Quopard LATAM S.A de C.V. Quopard LATAM S.A de C.V ofrece
          este sitio web, incluyendo toda la información, herramientas y
          servicios disponibles en este sitio para usted, el usuario,
          condicionado a la aceptación de todos los términos, condiciones,
          políticas y avisos aquí establecidos. Al visitar nuestro sitio y / o
          comprar algo de nosotros, usted participa en nuestro "Servicio" y
          acepta que quedará vinculado por los siguientes términos y condiciones
          ("Términos de Servicio", "Términos"), incluyendo los términos y
          condiciones y políticas adicionales que se hace referencia en este
          documento y / o disponibles por hipervínculo. Estas Condiciones del
          servicio se aplican a todos los usuarios del sitio, incluidos, entre
          otros, los usuarios que son navegadores, vendedores, clientes,
          comerciantes y/o colaboradores de contenidos. Lea atentamente estas
          Condiciones de servicio antes de acceder o utilizar nuestro sitio web.
          Al acceder o utilizar cualquier parte del sitio, usted acepta quedar
          vinculado por estas Condiciones de servicio. Si no acepta todos los
          términos y condiciones de este acuerdo, no podrá acceder al sitio web
          ni utilizar ninguno de los Servicios. Si estas Condiciones del
          servicio se consideran una oferta, su aceptación se limita
          expresamente a estas Condiciones del servicio. Cualquier nueva función
          o herramienta que se añada a la tienda actual también estará sujeta a
          las Condiciones del servicio. Puede consultar la versión más
          actualizada de las Condiciones del servicio en cualquier momento en
          esta página. Nos reservamos el derecho a actualizar, cambiar o
          sustituir cualquier parte de estas Condiciones del servicio mediante
          la publicación de actualizaciones y/o cambios en nuestro sitio web. Es
          su responsabilidad revisar esta página periódicamente para comprobar
          si se han producido cambios. El uso continuado o el acceso a la página
          web después de la publicación de cualquier cambio constituye la
          aceptación de dichos cambios. Nuestra tienda está alojada en Shopify
          Inc. Ellos nos proporcionan la plataforma de comercio electrónico en
          línea que nos permite vender nuestros productos y Servicios a usted.
          <br />
          <br />
          SECCIÓN 1 - TÉRMINOS DE LA TIENDA ONLINE Al aceptar estos Términos de
          Servicio, usted declara que es mayor de edad en su estado o provincia
          de residencia, o que es mayor de edad en su estado o provincia de
          residencia y nos ha dado su consentimiento para permitir que
          cualquiera de sus dependientes menores de edad utilice este sitio. No
          podrá utilizar nuestros productos para ningún fin ilegal o no
          autorizado ni podrá, en el uso del Servicio, infringir ninguna ley de
          su jurisdicción (incluidas, entre otras, las leyes sobre derechos de
          autor). No debe transmitir ningún gusano o virus ni ningún código de
          naturaleza destructiva. El incumplimiento o violación de cualquiera de
          las Condiciones dará lugar a la terminación inmediata de sus
          Servicios.
          <br />
          <br />
          SECCIÓN 2 - CONDICIONES GENERALES Nos reservamos el derecho a denegar
          el servicio a cualquier persona por cualquier motivo y en cualquier
          momento. Usted entiende que su contenido (sin incluir la información
          de la tarjeta de crédito), puede ser transferido sin cifrar e implicar
          (a) transmisiones a través de varias redes; y (b) cambios para
          ajustarse y adaptarse a los requisitos técnicos de las redes o
          dispositivos de conexión. La información de las tarjetas de crédito
          siempre se encripta durante la transferencia a través de las redes.
          Usted se compromete a no reproducir, duplicar, copiar, vender,
          revender o explotar ninguna parte del Servicio, el uso del Servicio, o
          el acceso al Servicio o cualquier contacto en el sitio web a través
          del cual se presta el servicio, sin el permiso expreso y por escrito
          de nosotros. Los encabezados utilizados en este acuerdo se incluyen
          únicamente por comodidad y no limitarán ni afectarán de otro modo a
          estas Condiciones.
          <br />
          <br />
          SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y ACTUALIDAD DE LA INFORMACIÓN No
          nos hacemos responsables si la información disponible en este sitio no
          es exacta, completa o actual. El material de este sitio se proporciona
          únicamente a título informativo y no debe utilizarse como única base
          para tomar decisiones sin consultar fuentes de información primarias,
          más precisas, más completas o más actualizadas. La confianza
          depositada en el material de este sitio corre por cuenta y riesgo del
          usuario. Este sitio puede contener cierta información histórica. La
          información histórica, necesariamente, no es actual y se proporciona
          únicamente como referencia. Nos reservamos el derecho de modificar el
          contenido de este sitio en cualquier momento, pero no tenemos ninguna
          obligación de actualizar ninguna información en nuestro sitio. Usted
          acepta que es su responsabilidad supervisar los cambios en nuestro
          sitio.
          <br />
          <br />
          SECCIÓN 4 - MODIFICACIONES DEL SERVICIO Y PRECIOS Los precios de
          nuestros productos están sujetos a cambios sin previo aviso. Nos
          reservamos el derecho de modificar o interrumpir en cualquier momento
          el Servicio (o cualquier parte o contenido del mismo) sin previo
          aviso. No seremos responsables ante usted ni ante terceros por ninguna
          modificación, cambio de precio, suspensión o interrupción del
          Servicio.
          <br />
          <br />
          SECCIÓN 5 - PRODUCTOS O SERVICIOS (si procede) Ciertos productos o
          Servicios pueden estar disponibles exclusivamente en línea a través
          del sitio web. Estos productos o Servicios pueden tener cantidades
          limitadas y están sujetos a devolución o cambio sólo de acuerdo con
          nuestra Política de Devoluciones. Para consultar nuestra política de
          devoluciones, visite [ENLACE A LA POLÍTICA DE DEVOLUCIONES]. Hemos
          hecho todo lo posible para mostrar con la mayor precisión posible los
          colores y las imágenes de nuestros productos que aparecen en la
          tienda. No podemos garantizar que la visualización de cualquier color
          en el monitor de su ordenador sea exacta. Nos reservamos el derecho,
          pero no estamos obligados, a limitar las ventas de nuestros productos
          o Servicios a cualquier persona, región geográfica o jurisdicción.
          Podemos ejercer este derecho caso por caso. Nos reservamos el derecho
          a limitar las cantidades de cualquiera de los productos o Servicios
          que ofrecemos. Todas las descripciones de productos o precios de
          productos están sujetos a cambios en cualquier momento sin previo
          aviso, a nuestra entera discreción. Nos reservamos el derecho a
          interrumpir cualquier producto en cualquier momento. Cualquier oferta
          de cualquier producto o servicio realizada en este sitio es nula donde
          esté prohibida. No garantizamos que la calidad de los productos,
          Servicios, información u otro material adquirido u obtenido por usted
          satisfaga sus expectativas, ni que se corrija cualquier error en el
          Servicio.
          <br />
          <br />
          SECCIÓN 6 - EXACTITUD DE LA INFORMACIÓN DE FACTURACIÓN Y DE LA CUENTA
          Nos reservamos el derecho a rechazar cualquier pedido que nos haga.
          Podemos, a nuestra entera discreción, limitar o cancelar las
          cantidades compradas por persona, por hogar o por pedido. Estas
          restricciones pueden incluir pedidos realizados por o bajo la misma
          cuenta de cliente, la misma tarjeta de crédito y/o pedidos que
          utilicen la misma dirección de facturación y/o envío. En caso de que
          modifiquemos o cancelemos un pedido, intentaremos notificárselo
          poniéndonos en contacto con la dirección de correo electrónico y/o
          dirección de facturación/número de teléfono facilitados en el momento
          de realizar el pedido. Nos reservamos el derecho a limitar o prohibir
          pedidos que, a nuestro juicio, parezcan realizados por comerciantes,
          revendedores o distribuidores. Usted se compromete a proporcionar
          información de compra y de cuenta actual, completa y precisa para
          todas las compras realizadas en nuestra tienda. Usted se compromete a
          actualizar puntualmente su cuenta y otra información, incluyendo su
          dirección de correo electrónico y números de tarjetas de crédito y
          fechas de caducidad, para que podamos completar sus transacciones y en
          contacto con usted cuando sea necesario. Para más detalles, consulte
          nuestra Política de devoluciones.
          <br />
          <br />
          SECCIÓN 7 - HERRAMIENTAS OPCIONALES Es posible que le proporcionemos
          acceso a herramientas de terceros sobre las que no ejercemos ningún
          control. Usted reconoce y acepta que le proporcionamos acceso a dichas
          herramientas "tal cual" y "según disponibilidad", sin garantías,
          declaraciones ni condiciones de ningún tipo y sin ningún tipo de
          respaldo. No tendremos responsabilidad alguna derivada o relacionada
          con el uso que usted haga de las herramientas opcionales de terceros.
          Cualquier uso por su parte de las herramientas opcionales ofrecidas a
          través del sitio es enteramente bajo su propio riesgo y discreción y
          debe asegurarse de que está familiarizado y aprueba los términos en
          los que las herramientas son proporcionadas por el proveedor o
          proveedores de terceros pertinentes. También es posible que en el
          futuro ofrezcamos nuevos Servicios y/o funciones a través del sitio
          web (incluido el lanzamiento de nuevas herramientas y recursos). Estas
          nuevas funciones y/o Servicios también estarán sujetos a las presentes
          Condiciones de Servicio.
          <br />
          <br />
          SECCIÓN 8 - ENLACES DE TERCEROS Ciertos contenidos, productos y
          Servicios disponibles a través de nuestro Servicio pueden incluir
          materiales de terceros. Los enlaces de terceros en este sitio pueden
          dirigirle a sitios web de terceros que no están afiliados a nosotros.
          No somos responsables de examinar o evaluar el contenido o la
          exactitud y no garantizamos y no tendremos ninguna obligación o
          responsabilidad por cualquier material de terceros o sitios web, o por
          cualquier otro material, productos o Servicios de terceros. No nos
          hacemos responsables de ningún daño o perjuicio relacionado con la
          compra o el uso de bienes, Servicios, recursos, contenidos o cualquier
          otra transacción realizada en relación con sitios web de terceros.
          Revise detenidamente las políticas y prácticas de terceros y asegúrese
          de comprenderlas antes de realizar cualquier transacción. Las quejas,
          reclamaciones, inquietudes o preguntas relacionadas con productos de
          terceros deben dirigirse al tercero en cuestión.
          <br />
          <br />
          SECCIÓN 9 - COMENTARIOS, OPINIONES Y OTROS ENVÍOS DE LOS USUARIOS Si,
          a petición nuestra, envía determinados envíos específicos (por
          ejemplo, participaciones en concursos) o sin que nosotros se lo
          pidamos envía ideas creativas, sugerencias, propuestas, planes u otros
          materiales, ya sea en línea, por correo electrónico, por correo postal
          o de otro modo (colectivamente, "comentarios"), usted acepta que
          podamos, en cualquier momento y sin restricciones, editar, copiar,
          publicar, distribuir, traducir y utilizar de otro modo en cualquier
          medio cualquier comentario que nos envíe. No estamos ni estaremos
          obligados (1) a mantener la confidencialidad de ningún comentario; (2)
          a pagar una compensación por ningún comentario; ni (3) a responder a
          ningún comentario. Podemos, pero no tenemos ninguna obligación de,
          controlar, editar o eliminar el contenido que determinemos, a nuestra
          entera discreción, que es ilegal, ofensivo, amenazador, calumnioso,
          difamatorio, pornográfico, obsceno o de otra manera objetable o que
          viole la propiedad intelectual de cualquier parte o estas Condiciones
          del servicio. Usted acepta que sus comentarios no violarán ningún
          derecho de terceros, incluidos los derechos de autor, marca
          registrada, privacidad, personalidad u otro derecho personal o de
          propiedad. Asimismo, acepta que sus comentarios no contendrán material
          difamatorio o ilegal, abusivo u obsceno, ni contendrán virus
          informáticos u otros programas maliciosos que puedan afectar de algún
          modo al funcionamiento del Servicio o de cualquier sitio web
          relacionado. No puede utilizar una dirección de correo electrónico
          falsa, hacerse pasar por otra persona que no sea usted, ni inducirnos
          a error a nosotros o a terceros sobre el origen de los comentarios.
          Usted es el único responsable de los comentarios que haga y de su
          exactitud. No asumimos responsabilidad alguna por los comentarios que
          usted o terceros publiquen.
          <br />
          <br />
          SECCIÓN 10 - INFORMACIÓN PERSONAL El envío de información personal a
          través de la tienda se rige por nuestra Política de Privacidad. Para
          ver nuestra Política de Privacidad, consulte [ENLACE A LA POLÍTICA DE
          PRIVACIDAD].
          <br />
          <br />
          SECCIÓN 11 - ERRORES, INEXACTITUDES Y OMISIONES Ocasionalmente puede
          haber información en nuestro sitio o en el Servicio que contenga
          errores tipográficos, inexactitudes u omisiones que puedan estar
          relacionadas con descripciones de productos, precios, promociones,
          ofertas, gastos de envío de productos, tiempos de tránsito y
          disponibilidad. Nos reservamos el derecho de corregir cualquier error,
          inexactitud u omisión, y de cambiar o actualizar la información o
          cancelar pedidos si cualquier información en el Servicio o en
          cualquier sitio web relacionado es inexacta en cualquier momento sin
          previo aviso (incluso después de que usted haya enviado su pedido). No
          asumimos ninguna obligación de actualizar, modificar o aclarar la
          información en el Servicio o en cualquier sitio web relacionado,
          incluyendo, sin limitación, la información de precios, excepto cuando
          sea requerido por la ley. Ninguna fecha especificada de actualización
          o actualización aplicada en el Servicio o en cualquier sitio web
          relacionado, debe ser tomada para indicar que toda la información en
          el Servicio o en cualquier sitio web relacionado ha sido modificada o
          actualizada.
          <br />
          <br />
          SECCIÓN 12 - USOS PROHIBIDOS Además de otras prohibiciones
          establecidas en las Condiciones del Servicio, se le prohíbe utilizar
          el sitio o su contenido (a) para cualquier fin ilícito; (b) para
          solicitar a otros que realicen o participen en cualquier acto ilícito;
          (c) para infringir cualquier reglamento, norma, ley u ordenanza local
          internacional, federal, provincial o estatal; (d) para infringir o
          violar nuestros derechos de propiedad intelectual o los derechos de
          propiedad intelectual de otros; (e) acosar, abusar, insultar, dañar,
          difamar, calumniar, menospreciar, intimidar o discriminar por motivos
          de género, orientación sexual, religión, etnia, raza, edad,
          nacionalidad o discapacidad; (f) enviar información falsa o engañosa;
          (g) cargar o transmitir virus o cualquier otro tipo de código
          malicioso que afecte o pueda afectar de algún modo a la funcionalidad
          o al funcionamiento del Servicio o de cualquier sitio web relacionado,
          de otros sitios web o de Internet; (h) recopilar o rastrear la
          información personal de otros; (i) para spam, phishing, pharm,
          pretext, spider, crawl, o scrape; (j) para cualquier propósito obsceno
          o inmoral; o (k) para interferir o eludir las funciones de seguridad
          del Servicio o de cualquier sitio web relacionado, otros sitios web o
          Internet. Nos reservamos el derecho a poner fin a su uso del Servicio
          o de cualquier sitio web relacionado por infringir cualquiera de los
          usos prohibidos.
          <br />
          <br />
          SECCIÓN 13 - EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD No
          garantizamos, declaramos ni aseguramos que el uso que usted haga de
          nuestro servicio sea ininterrumpido, puntual, seguro o libre de
          errores. No garantizamos que los resultados que puedan obtenerse del
          uso del servicio sean precisos o fiables. Usted acepta que, de vez en
          cuando, podamos suprimir el servicio por periodos de tiempo
          indefinidos o cancelarlo en cualquier momento, sin previo aviso. Usted
          acepta expresamente que el uso que haga del servicio, o la
          imposibilidad de utilizarlo, será por su cuenta y riesgo. El servicio
          y todos los productos y servicios entregados a usted a través del
          servicio son (salvo que se indique expresamente por nosotros)
          proporcionado "tal cual" y "según esté disponible" para su uso, sin
          ninguna representación, garantías o condiciones de ningún tipo, ya sea
          expresa o implícita, incluyendo todas las garantías o condiciones
          implícitas de comerciabilidad, calidad comercial, idoneidad para un
          propósito particular, durabilidad, título y no infracción. En ningún
          caso Quopard LATAM S.A de C. V, nuestros directores, funcionarios,
          empleados, afiliados, agentes, contratistas, pasantes, proveedores,
          prestadores de servicios o licenciantes serán responsables por
          cualquier lesión, pérdida, reclamo, o cualquier daño directo,
          indirecto, incidental, punitivo, especial o consecuente de cualquier
          tipo, incluyendo, sin limitación, la pérdida de beneficios, pérdida de
          ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o
          cualquier daño similar, ya sea basado en contrato, agravio (incluyendo
          negligencia), responsabilidad estricta o de otro tipo, derivados del
          uso que usted haga del servicio o de los productos adquiridos mediante
          el servicio, o de cualquier otra reclamación relacionada de cualquier
          modo con el uso que usted haga del servicio o de cualquier producto,
          incluidos, a título meramente enunciativo y no limitativo, los errores
          u omisiones en cualquier contenido, o cualquier pérdida o daño de
          cualquier tipo en que se incurra como resultado del uso del servicio o
          de cualquier contenido (o producto) publicado, transmitido o puesto a
          disposición de cualquier otra forma a través del servicio, aun cuando
          se haya advertido de su posibilidad. Debido a que algunos estados o
          jurisdicciones no permiten la exclusión o la limitación de
          responsabilidad por daños consecuenciales o incidentales, en dichos
          estados o jurisdicciones, nuestra responsabilidad se limitará al
          máximo permitido por la ley.
          <br />
          <br />
          SECCIÓN 14 - INDEMNIZACIÓN Usted se compromete a indemnizar, defender
          y mantener indemne a Quopard LATAM S.A de C.V y nuestra matriz,
          subsidiarias, afiliadas, socios, funcionarios, directores, agentes,
          contratistas, licenciantes, proveedores de servicios, subcontratistas,
          proveedores, internos y empleados, de cualquier reclamo o demanda,
          incluyendo honorarios razonables de abogados, hecha por cualquier
          tercero debido a o como resultado de su incumplimiento de estos
          Términos de Servicio o los documentos que incorporan por referencia, o
          su violación de cualquier ley o los derechos de un tercero.
          <br />
          <br />
          SECCIÓN 15 - DIVISIBILIDAD En caso de que se determine que alguna
          disposición de estas Condiciones del servicio es ilegal, nula o
          inaplicable, dicha disposición será, no obstante, aplicable en la
          máxima medida permitida por la legislación aplicable, y la parte
          inaplicable se considerará separada de estas Condiciones del servicio,
          dicha determinación no afectará a la validez y aplicabilidad de las
          demás disposiciones restantes.
          <br />
          <br />
          SECCIÓN 16 - TERMINACIÓN Las obligaciones y responsabilidades de las
          partes contraídas con anterioridad a la fecha de terminación
          sobrevivirán a la terminación del presente acuerdo a todos los
          efectos. Estas Condiciones del servicio son efectivas a menos y hasta
          que sean rescindidas por usted o por nosotros. Usted puede rescindir
          estas Condiciones del servicio en cualquier momento notificándonos que
          ya no desea utilizar nuestros Servicios, o cuando deje de utilizar
          nuestro sitio. Si a nuestro juicio usted incumple, o sospechamos que
          ha incumplido, cualquier término o disposición de estas Condiciones
          del servicio, también podremos rescindir este acuerdo en cualquier
          momento sin previo aviso y usted seguirá siendo responsable de todos
          los importes adeudados hasta la fecha de rescisión inclusive; y/o, en
          consecuencia, podremos denegarle el acceso a nuestros Servicios (o a
          cualquier parte de los mismos).
          <br />
          <br />
          SECCIÓN 17 - ACUERDO COMPLETO El hecho de que no ejerzamos o hagamos
          valer cualquier derecho o disposición de estas Condiciones del
          servicio no constituirá una renuncia a dicho derecho o disposición.
          Las presentes Condiciones del servicio y cualesquiera políticas o
          normas de funcionamiento publicadas por nosotros en este sitio o con
          respecto al Servicio constituyen la totalidad del acuerdo y
          entendimiento entre usted y nosotros y rigen el uso que usted haga del
          Servicio, sustituyendo a cualesquiera acuerdos, comunicaciones y
          propuestas anteriores o contemporáneos, ya sean orales o escritos,
          entre usted y nosotros (incluidas, a título meramente enunciativo y no
          limitativo, cualesquiera versiones anteriores de las Condiciones del
          servicio). Cualquier ambigüedad en la interpretación de estas
          Condiciones del servicio no se interpretará en contra de la parte que
          las haya redactado.
          <br />
          <br />
          SECCIÓN 18 - LEGISLACIÓN APLICABLE Estos Términos de Servicio y
          cualquier acuerdo por separado mediante el cual le proporcionamos
          Servicios se regirán e interpretarán de conformidad con las leyes de
          México.
          <br />
          <br />
          SECCIÓN 19 - CAMBIOS EN LAS CONDICIONES DE SERVICIO Usted puede
          revisar la versión más actualizada de los Términos de Servicio en
          cualquier momento en esta página. Nos reservamos el derecho, a nuestra
          entera discreción, de actualizar, cambiar o sustituir cualquier parte
          de estas Condiciones del servicio mediante la publicación de
          actualizaciones y cambios en nuestro sitio web. Es su responsabilidad
          comprobar periódicamente si se han producido cambios en nuestro sitio
          web. El uso continuado o el acceso a nuestro sitio web o al Servicio
          tras la publicación de cualquier cambio en estas Condiciones del
          servicio constituye la aceptación de dichos cambios.
          <br />
          <br />
          SECCIÓN 20 - INFORMACIÓN DE CONTACTO Las preguntas sobre las
          Condiciones del servicio deben enviarse a alberto@quopard.com.
        </Text>
      </Div>

      <Footer />
    </div>
  </Layout>
);

export default ServicePage;
